import React from "react"
import { Reset } from "styled-reset"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import { normalize } from "polished"
import { theme } from "./src/utils/themeGet"
import { parseTheme } from "@sqymagma/theme"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

import { CookiesProvider } from "react-cookie"
import Marketing from "./src/components/marketing"

import _mainTheme from "./src/themes/siteTheme"

const defaultTheme = parseTheme({ ..._mainTheme })

const GlobalStyles = createGlobalStyle`
  ${normalize()}
  html, body {
    line-height: ${theme("lineHeightBase")};
    color: ${theme("color.text01")};
    background-color: ${theme("color.primaryBackground")};
    min-height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
  }
  * { box-sizing: border-box; }
  a { color: inherit; text-decoration: none; }
  img { display: block; width: 100%; max-width: 100%; }
  em { font-style: italic; }

`

const Cookie = styled(CookieConsent)`
  font-family: "Rubik, sans-serif";
`

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CookiesProvider>
        <>
          <Reset />
          <GlobalStyles />
          {element}
        </>
        <Marketing />
        <Cookie
          style={{
            background: "#FFF6D8",
            color: "#777",
            minHeight: "64px",
            fontSize: "15px",
            fontFamily: "Rubik, sans-serif",
            padding: "24px",
          }}
          buttonStyle={{
            background: "#3E9174",
            color: "#fff",
            fontSize: "15px",
            fontFamily: "Rubik, sans-serif",
            padding: "8px 16px",
          }}
          location="bottom"
          buttonText="Aceptar"
          cookieName="livingspaces"
          expires={150}
        >
          Esta web utiliza cookies, al continuar aceptas su uso. Puedes consultar nuestra{" "}
          <Link to="/cookies" style={{ color: "#3E9174", margin: "24px 24px 24px 0" }}>
            política de cookies
          </Link>
        </Cookie>
      </CookiesProvider>
    </ThemeProvider>
  )
}
