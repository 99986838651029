

import React from "react"
import styled, { css } from "styled-components"
import { space, color, layout } from "styled-system"

import Logo from "./svg/icn-magma-logo.svg"
import GatsbyLogo from "./svg/icn-gatsby-logo.svg"
import LSicon from "./svg/logoIcon.svg"
import Ilimp from "./svg/iconLimpieza.svg"
import Ipromo from "./svg/iconPromo.svg"
import LSname from "./svg/logoLivingSpaces.svg"
import Fb from "./svg/social_fb.svg"
import Instagram from "./svg/social_instagram.svg"
import Twitter from "./svg/social_twitter.svg"
import Medium from "./svg/social_medium.svg"
import Linkedin from "./svg/social_linkedin.svg"
import IcnAC from "./svg/iconAC.svg"
import Iascensor from "./svg/iconAscensor.svg"
import Iaseo from "./svg/iconAseo.svg"
import Idorm from "./svg/iconDormitorio.svg"
import Iescuadra from "./svg/iconEscuadra.svg"
import Iestado from "./svg/iconEstado.svg"
import Igarage from "./svg/iconGarage.svg"
import Ipiscina from "./svg/iconPiscina.svg"
import Iplanta from "./svg/iconPlanta.svg"
import Iterraza from "./svg/iconTerraza.svg"
import Itrastero from "./svg/iconTrastero.svg"
import Ialquiler from "./svg/iconAlquiler.svg"
import icnlic from "./svg/iconALicencia.svg"
import Icnbuscar from "./svg/iconBuscar.svg"
import IcnCalidad from "./svg/iconCalidad.svg"
import IcnCalendar from "./svg/calendar.svg"
import OpenOutside from "./svg/open_outside.svg"
import Photos from "./svg/photos.svg"
import IcnDecor from "./svg/iconDecor.svg"
import IcnPlus from "./svg/iconPlus.svg"
import Icnless from "./svg/iconLess.svg"
import IcnGestion from "./svg/iconGestion.svg"
import IcnInq from "./svg/iconInq.svg"
import IcnLic2 from "./svg/iconLic.svg"
import IcnNorma from "./svg/iconNormativa.svg"
import IcnProy from "./svg/iconProyecto.svg"
import IcnReforma from "./svg/iconReforma.svg"
import IcnValora from "./svg/iconValoracion.svg"
import IcnVia from "./svg/iconViabilidad.svg"
import IcnCirc from "./svg/iconCirculo.svg"
import IcnCoc from "./svg/iconCocina.svg"
import IcnCodigo from "./svg/iconCodTec.svg"
import IcnDim from "./svg/iconDim.svg"
import IcnProt from "./svg/iconProteccion.svg"
import IcnIlumina from "./svg/iconIlumina.svg"
import IcnSuelo from "./svg/iconSuelo.svg"
import IcnVentila from "./svg/iconVentila.svg"
import icnvivmin from "./svg/iconVivMin.svg"
import IcnEscudo from "./svg/iconEscudo.svg"
import IcnPhone from "./svg/iconPhone.svg"
import IcnFlor from "./svg/iconFlor.svg"
import IcnEstrella from "./svg/iconEstrella.svg"
import IcnReloj from "./svg/iconReloj.svg"
import Ilexp from "./svg/ilus_exp.svg"
import Ilalq from "./svg/ilus_alquiler.svg"
import Ilven from "./svg/ilus_venta.svg"
import Adown from "./svg/uiArrowDown.svg"
import Aleft from "./svg/uiArrowLeft.svg"
import Aup from "./svg/uiArrowUp.svg"
import Aright from "./svg/uiArrowRight.svg"
import Close from "./svg/uiClose.svg"
import Menu from "./svg/uiMenu.svg"
import home from "./svg/uiHome.svg"
import Share from "./svg/uiShare.svg"

const Base = css`
  ${space}
  ${color}
  ${layout}
  * {
    fill: ${p => p.fill};
    fill: ${p => p.theme.color[p.fill]};
  }
`

const StyledLogo = styled(Logo)`${Base}`
const StyledGatsbyLogo = styled(GatsbyLogo)`${Base}`
const StyledLSicon = styled(LSicon)`${Base}`
const StyledIlimp = styled(Ilimp)`${Base}`
const StyledIpromo = styled(Ipromo)`${Base}`
const StyledLSname = styled(LSname)`${Base}`
const StyledFb = styled(Fb)`${Base}`
const StyledInstagram = styled(Instagram)`${Base}`
const StyledTwitter = styled(Twitter)`${Base}`
const StyledMedium = styled(Medium)`${Base}`
const StyledLinkedin = styled(Linkedin)`${Base}`
const StyledIcnAC = styled(IcnAC)`${Base}`
const StyledIascensor = styled(Iascensor)`${Base}`
const StyledIaseo = styled(Iaseo)`${Base}`
const StyledIdorm = styled(Idorm)`${Base}`
const StyledIescuadra = styled(Iescuadra)`${Base}`
const StyledIestado = styled(Iestado)`${Base}`
const StyledIgarage = styled(Igarage)`${Base}`
const StyledIpiscina = styled(Ipiscina)`${Base}`
const StyledIplanta = styled(Iplanta)`${Base}`
const StyledIterraza = styled(Iterraza)`${Base}`
const StyledItrastero = styled(Itrastero)`${Base}`
const StyledIalquiler = styled(Ialquiler)`${Base}`
const Styledicnlic = styled(icnlic)`${Base}`
const StyledIcnbuscar = styled(Icnbuscar)`${Base}`
const StyledIcnCalidad = styled(IcnCalidad)`${Base}`
const StyledIcnCalendar = styled(IcnCalendar)`${Base}`
const StyledOpenOutside = styled(OpenOutside)`${Base}`
const StyledPhotos = styled(Photos)`${Base}`
const StyledIcnDecor = styled(IcnDecor)`${Base}`
const StyledIcnPlus = styled(IcnPlus)`${Base}`
const StyledIcnless = styled(Icnless)`${Base}`
const StyledIcnGestion = styled(IcnGestion)`${Base}`
const StyledIcnInq = styled(IcnInq)`${Base}`
const StyledIcnLic2 = styled(IcnLic2)`${Base}`
const StyledIcnNorma = styled(IcnNorma)`${Base}`
const StyledIcnProy = styled(IcnProy)`${Base}`
const StyledIcnReforma = styled(IcnReforma)`${Base}`
const StyledIcnValora = styled(IcnValora)`${Base}`
const StyledIcnVia = styled(IcnVia)`${Base}`
const StyledIcnCirc = styled(IcnCirc)`${Base}`
const StyledIcnCoc = styled(IcnCoc)`${Base}`
const StyledIcnCodigo = styled(IcnCodigo)`${Base}`
const StyledIcnDim = styled(IcnDim)`${Base}`
const StyledIcnProt = styled(IcnProt)`${Base}`
const StyledIcnIlumina = styled(IcnIlumina)`${Base}`
const StyledIcnSuelo = styled(IcnSuelo)`${Base}`
const StyledIcnVentila = styled(IcnVentila)`${Base}`
const Styledicnvivmin = styled(icnvivmin)`${Base}`
const StyledIcnEscudo = styled(IcnEscudo)`${Base}`
const StyledIcnPhone = styled(IcnPhone)`${Base}`
const StyledIcnFlor = styled(IcnFlor)`${Base}`
const StyledIcnEstrella = styled(IcnEstrella)`${Base}`
const StyledIcnReloj = styled(IcnReloj)`${Base}`
const StyledIlexp = styled(Ilexp)`${Base}`
const StyledIlalq = styled(Ilalq)`${Base}`
const StyledIlven = styled(Ilven)`${Base}`
const StyledAdown = styled(Adown)`${Base}`
const StyledAleft = styled(Aleft)`${Base}`
const StyledAup = styled(Aup)`${Base}`
const StyledAright = styled(Aright)`${Base}`
const StyledClose = styled(Close)`${Base}`
const StyledMenu = styled(Menu)`${Base}`
const Styledhome = styled(home)`${Base}`
const StyledShare = styled(Share)`${Base}`


const BaseLogo = ({ name, mode, fill, ...props }) => (
  <StyledLogo name={name} mode={mode} fill={fill} {...props} />
)

const BaseGatsbyLogo = ({ name, mode, fill, ...props }) => (
  <StyledGatsbyLogo name={name} mode={mode} fill={fill} {...props} />
)

const BaseLSicon = ({ name, mode, fill, ...props }) => (
  <StyledLSicon name={name} mode={mode} fill={fill} {...props} />
)

const BaseIlimp = ({ name, mode, fill, ...props }) => (
  <StyledIlimp name={name} mode={mode} fill={fill} {...props} />
)

const BaseIpromo = ({ name, mode, fill, ...props }) => (
  <StyledIpromo name={name} mode={mode} fill={fill} {...props} />
)

const BaseLSname = ({ name, mode, fill, ...props }) => (
  <StyledLSname name={name} mode={mode} fill={fill} {...props} />
)

const BaseFb = ({ name, mode, fill, ...props }) => (
  <StyledFb name={name} mode={mode} fill={fill} {...props} />
)

const BaseInstagram = ({ name, mode, fill, ...props }) => (
  <StyledInstagram name={name} mode={mode} fill={fill} {...props} />
)

const BaseTwitter = ({ name, mode, fill, ...props }) => (
  <StyledTwitter name={name} mode={mode} fill={fill} {...props} />
)

const BaseMedium = ({ name, mode, fill, ...props }) => (
  <StyledMedium name={name} mode={mode} fill={fill} {...props} />
)

const BaseLinkedin = ({ name, mode, fill, ...props }) => (
  <StyledLinkedin name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnAC = ({ name, mode, fill, ...props }) => (
  <StyledIcnAC name={name} mode={mode} fill={fill} {...props} />
)

const BaseIascensor = ({ name, mode, fill, ...props }) => (
  <StyledIascensor name={name} mode={mode} fill={fill} {...props} />
)

const BaseIaseo = ({ name, mode, fill, ...props }) => (
  <StyledIaseo name={name} mode={mode} fill={fill} {...props} />
)

const BaseIdorm = ({ name, mode, fill, ...props }) => (
  <StyledIdorm name={name} mode={mode} fill={fill} {...props} />
)

const BaseIescuadra = ({ name, mode, fill, ...props }) => (
  <StyledIescuadra name={name} mode={mode} fill={fill} {...props} />
)

const BaseIestado = ({ name, mode, fill, ...props }) => (
  <StyledIestado name={name} mode={mode} fill={fill} {...props} />
)

const BaseIgarage = ({ name, mode, fill, ...props }) => (
  <StyledIgarage name={name} mode={mode} fill={fill} {...props} />
)

const BaseIpiscina = ({ name, mode, fill, ...props }) => (
  <StyledIpiscina name={name} mode={mode} fill={fill} {...props} />
)

const BaseIplanta = ({ name, mode, fill, ...props }) => (
  <StyledIplanta name={name} mode={mode} fill={fill} {...props} />
)

const BaseIterraza = ({ name, mode, fill, ...props }) => (
  <StyledIterraza name={name} mode={mode} fill={fill} {...props} />
)

const BaseItrastero = ({ name, mode, fill, ...props }) => (
  <StyledItrastero name={name} mode={mode} fill={fill} {...props} />
)

const BaseIalquiler = ({ name, mode, fill, ...props }) => (
  <StyledIalquiler name={name} mode={mode} fill={fill} {...props} />
)

const Baseicnlic = ({ name, mode, fill, ...props }) => (
  <Styledicnlic name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnbuscar = ({ name, mode, fill, ...props }) => (
  <StyledIcnbuscar name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnCalidad = ({ name, mode, fill, ...props }) => (
  <StyledIcnCalidad name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnCalendar = ({ name, mode, fill, ...props }) => (
  <StyledIcnCalendar name={name} mode={mode} fill={fill} {...props} />
)

const BaseOpenOutside = ({ name, mode, fill, ...props }) => (
  <StyledOpenOutside name={name} mode={mode} fill={fill} {...props} />
)

const BasePhotos = ({ name, mode, fill, ...props }) => (
  <StyledPhotos name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnDecor = ({ name, mode, fill, ...props }) => (
  <StyledIcnDecor name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnPlus = ({ name, mode, fill, ...props }) => (
  <StyledIcnPlus name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnless = ({ name, mode, fill, ...props }) => (
  <StyledIcnless name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnGestion = ({ name, mode, fill, ...props }) => (
  <StyledIcnGestion name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnInq = ({ name, mode, fill, ...props }) => (
  <StyledIcnInq name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnLic2 = ({ name, mode, fill, ...props }) => (
  <StyledIcnLic2 name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnNorma = ({ name, mode, fill, ...props }) => (
  <StyledIcnNorma name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnProy = ({ name, mode, fill, ...props }) => (
  <StyledIcnProy name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnReforma = ({ name, mode, fill, ...props }) => (
  <StyledIcnReforma name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnValora = ({ name, mode, fill, ...props }) => (
  <StyledIcnValora name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnVia = ({ name, mode, fill, ...props }) => (
  <StyledIcnVia name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnCirc = ({ name, mode, fill, ...props }) => (
  <StyledIcnCirc name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnCoc = ({ name, mode, fill, ...props }) => (
  <StyledIcnCoc name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnCodigo = ({ name, mode, fill, ...props }) => (
  <StyledIcnCodigo name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnDim = ({ name, mode, fill, ...props }) => (
  <StyledIcnDim name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnProt = ({ name, mode, fill, ...props }) => (
  <StyledIcnProt name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnIlumina = ({ name, mode, fill, ...props }) => (
  <StyledIcnIlumina name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnSuelo = ({ name, mode, fill, ...props }) => (
  <StyledIcnSuelo name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnVentila = ({ name, mode, fill, ...props }) => (
  <StyledIcnVentila name={name} mode={mode} fill={fill} {...props} />
)

const Baseicnvivmin = ({ name, mode, fill, ...props }) => (
  <Styledicnvivmin name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnEscudo = ({ name, mode, fill, ...props }) => (
  <StyledIcnEscudo name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnPhone = ({ name, mode, fill, ...props }) => (
  <StyledIcnPhone name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnFlor = ({ name, mode, fill, ...props }) => (
  <StyledIcnFlor name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnEstrella = ({ name, mode, fill, ...props }) => (
  <StyledIcnEstrella name={name} mode={mode} fill={fill} {...props} />
)

const BaseIcnReloj = ({ name, mode, fill, ...props }) => (
  <StyledIcnReloj name={name} mode={mode} fill={fill} {...props} />
)

const BaseIlexp = ({ name, mode, fill, ...props }) => (
  <StyledIlexp name={name} mode={mode} fill={fill} {...props} />
)

const BaseIlalq = ({ name, mode, fill, ...props }) => (
  <StyledIlalq name={name} mode={mode} fill={fill} {...props} />
)

const BaseIlven = ({ name, mode, fill, ...props }) => (
  <StyledIlven name={name} mode={mode} fill={fill} {...props} />
)

const BaseAdown = ({ name, mode, fill, ...props }) => (
  <StyledAdown name={name} mode={mode} fill={fill} {...props} />
)

const BaseAleft = ({ name, mode, fill, ...props }) => (
  <StyledAleft name={name} mode={mode} fill={fill} {...props} />
)

const BaseAup = ({ name, mode, fill, ...props }) => (
  <StyledAup name={name} mode={mode} fill={fill} {...props} />
)

const BaseAright = ({ name, mode, fill, ...props }) => (
  <StyledAright name={name} mode={mode} fill={fill} {...props} />
)

const BaseClose = ({ name, mode, fill, ...props }) => (
  <StyledClose name={name} mode={mode} fill={fill} {...props} />
)

const BaseMenu = ({ name, mode, fill, ...props }) => (
  <StyledMenu name={name} mode={mode} fill={fill} {...props} />
)

const Basehome = ({ name, mode, fill, ...props }) => (
  <Styledhome name={name} mode={mode} fill={fill} {...props} />
)

const BaseShare = ({ name, mode, fill, ...props }) => (
  <StyledShare name={name} mode={mode} fill={fill} {...props} />
)

export { BaseLogo as Logo }
export { BaseGatsbyLogo as GatsbyLogo }
export { BaseLSicon as LSicon }
export { BaseIlimp as Ilimp }
export { BaseIpromo as Ipromo }
export { BaseLSname as LSname }
export { BaseFb as Fb }
export { BaseInstagram as Instagram }
export { BaseTwitter as Twitter }
export { BaseMedium as Medium }
export { BaseLinkedin as Linkedin }
export { BaseIcnAC as IcnAC }
export { BaseIascensor as Iascensor }
export { BaseIaseo as Iaseo }
export { BaseIdorm as Idorm }
export { BaseIescuadra as Iescuadra }
export { BaseIestado as Iestado }
export { BaseIgarage as Igarage }
export { BaseIpiscina as Ipiscina }
export { BaseIplanta as Iplanta }
export { BaseIterraza as Iterraza }
export { BaseItrastero as Itrastero }
export { BaseIalquiler as Ialquiler }
export { Baseicnlic as icnlic }
export { BaseIcnbuscar as Icnbuscar }
export { BaseIcnCalidad as IcnCalidad }
export { BaseIcnCalendar as IcnCalendar }
export { BaseOpenOutside as OpenOutside }
export { BasePhotos as Photos }
export { BaseIcnDecor as IcnDecor }
export { BaseIcnPlus as IcnPlus }
export { BaseIcnless as Icnless }
export { BaseIcnGestion as IcnGestion }
export { BaseIcnInq as IcnInq }
export { BaseIcnLic2 as IcnLic2 }
export { BaseIcnNorma as IcnNorma }
export { BaseIcnProy as IcnProy }
export { BaseIcnReforma as IcnReforma }
export { BaseIcnValora as IcnValora }
export { BaseIcnVia as IcnVia }
export { BaseIcnCirc as IcnCirc }
export { BaseIcnCoc as IcnCoc }
export { BaseIcnCodigo as IcnCodigo }
export { BaseIcnDim as IcnDim }
export { BaseIcnProt as IcnProt }
export { BaseIcnIlumina as IcnIlumina }
export { BaseIcnSuelo as IcnSuelo }
export { BaseIcnVentila as IcnVentila }
export { Baseicnvivmin as icnvivmin }
export { BaseIcnEscudo as IcnEscudo }
export { BaseIcnPhone as IcnPhone }
export { BaseIcnFlor as IcnFlor }
export { BaseIcnEstrella as IcnEstrella }
export { BaseIcnReloj as IcnReloj }
export { BaseIlexp as Ilexp }
export { BaseIlalq as Ilalq }
export { BaseIlven as Ilven }
export { BaseAdown as Adown }
export { BaseAleft as Aleft }
export { BaseAup as Aup }
export { BaseAright as Aright }
export { BaseClose as Close }
export { BaseMenu as Menu }
export { Basehome as home }
export { BaseShare as Share }
 