import React from "react"
import styled, { css, createGlobalStyle } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

const Overlay = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => css`
    pointer-events: props.isActive ? 'auto' : ' none';
  `}
`

const overlayVariant = {
  init: { opacity: 0 },
  open: { opacity: 1 },
  close: { opacity: 0 },
}

const Modal = ({ isActive, toggleFunction, children }) => {
  //console.log("is active: " + isActive)
  const LockBody = createGlobalStyle`
    body{
      ${p =>
        p.lock
          ? css`
              overflow: hidden;
            `
          : css`
              overflow: visible;
            `}

    }
  `

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div initial="init" animate="open" exit="close">
          {/*  <LockBody lock />  */}
          <Overlay variants={overlayVariant} onClick={toggleFunction}>
            {children}
          </Overlay>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
