import React from "react"
import { Flex, Text } from "@sqymagma/elements"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import Linkexternal from "../components/linkexternal"
import { motion, AnimatePresence } from "framer-motion"
import { createGlobalStyle } from "styled-components"

const PushNav = createGlobalStyle`
.nav{
  padding-top: ${p => (p.show ? "40px" : "0px")};
}
`

const M34LastMinute = ({ lastmintext, lastminlink, openModal, close, show, ...props }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ y: -100, opacity: 0, maxHeight: 0 }}
          animate={{ y: 0, opacity: 1, maxHeight: 200 }}
          exit={{ y: -100, opacity: 0, maxHeight: 0 }}
        >
          <PushNav show />
          <Flex
            bg="brand02"
            position="fixed"
            width="100vw"
            p="8px"
            textAlign={["left", "center"]}
            alignItems="center"
            {...props}
          >
            <Text
              as="p"
              textStyle="p1_t"
              color="text01"
              display="block"
              width="100%"
              pr={["24px", 0]}
            >
              {lastmintext}
              <Linkexternal
                id="TM83-FaldonSuperior-ModuloCaptacionLeads-TextoSuscribeteAqui"
                href="#"
                titulo="suscribete"
                onClick={b => {
                  b.preventDefault()
                  openModal()
                }}
                css={`
                  padding-left: 8px;
                  font-weight: 500;
                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                {lastminlink}
              </Linkexternal>
            </Text>
            <IcnSystem
              onClick={e => {
                e.stopPropagation()
                close()
              }}
              open={false}
              cursor="pointer"
              name="close"
              height="24px"
              fill="text01"
              position="absolute"
              right={["0"]}
              top={["2px"]}
              css={`
                &:hover {
                  * {
                    opacity: 0.5;
                  }
                }
              `}
            />
          </Flex>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default M34LastMinute
