import { Box, Flex, Text } from "@sqymagma/elements"
import axios from "axios"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"
import Btn from "../components/button"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import Loader from "../components/Loader"
import { Link } from "gatsby"
import Linkexternal from "../components/linkexternal"
import { motion, AnimatePresence } from "framer-motion"

const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: left;
  border: 1px solid #f0f0f0;
  border-bottom: 3px solid #dadada;
  border-radius: 0 0 4px 4px;
  transition: 0.3s ease-in;
  box-sizing: content-box;
`

const StyledInput = styled(Field)`
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border: none;
  outline: none;
  padding: 0px 20px 15px 20px;
  font-family: ${p => p.theme.fontFamily.secondary};
  background: transparent;
`

const NextBtn = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 50px;
  height: 100%;
  color: ${p => p.theme.color.brand01};
  border: none;
  transition: 0.3s;
  cursor: pointer;
  outline: none;
  font-size: 50px;
  svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  &:hover {
    background: #f0f6f4;
  }
`

const ProgressBar = styled(Box)`
  height: 3px;
  position: absolute;
  bottom: -4px;
  border-radius: 0 0 0 3px;
  z-index: 500;
  right: 0;
  left: 0;
  transition: 0.3s ease-in;
`

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email("Capmo email debería ser válido user@domain.com")
    .required("Campo Email es obligatorio"),
  check: Yup.boolean().oneOf([true], "Marque esta casilla si desea continuar"),
})

const SubscribeForm = ({
  setClose,
  width,
  buttonWidth,
  buttonText,
  buttonPosition,
  border,
  bg,
  margin,
  tagidsubemail,
}) => {
  const emailInput = useRef()
  const [loaderVisible, setLoaderVisible] = useState(false)
  const formSubmit = () => {
    setTimeout(() => {
      setLoaderVisible(true)
    }, 500)
  }
  /* Server State Handling */
  const [serverState, setServerState] = useState()
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: "https://formspree.io/mgelgveo",
      data: values,
    })
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm()
        handleServerResponse(true, "Thanks!")
      })
      .catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, error.response.data.error)
      })
  }

  return (
    <Box height="150px" my={margin ? margin : null}>
      <Formik
        initialValues={{ email: "", check: false }}
        onSubmit={handleOnSubmit}
        validationSchema={formSchema}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form id="fs-frm" noValidate>
            <Box
              textStyle="h2"
              position="relative"
              width={width}
              height={["90px", "90px", "120px"]}
            >
              <Box display={serverState ? "none" : "block"}>
                <Loader display={loaderVisible ? "flex" : "none"} />
                <StyledBox bg="bg03" position="absolute">
                  <Text
                    as="label"
                    htmlFor="email"
                    caps
                    textStyle="p"
                    color="brand01"
                    p="15px 20px 0 20px"
                  >
                    ¿Cuál es tu email?
                  </Text>
                  <Box fontSize={["20px", "20px", "26px"]} p={["0px 20px 15px 20px"]}>
                    <StyledInput
                      innerRef={emailInput}
                      id="email"
                      type="email"
                      name="email"
                      autoFocus
                    />
                  </Box>

                  <NextBtn
                    as="button"
                    type="submit"
                    disabled={isSubmitting}
                    bg={touched.email && !errors.email && !errors.check ? "#f0f6f4" : null}
                    onClick={
                      touched.email && !errors.email && !errors.check
                        ? formSubmit
                        : () => emailInput.current.focus()
                    }
                    id={tagidsubemail}
                  >
                    <Box display={{ default: "none", s: "block" }}>
                      <IcnSystem name="aright" length={[40, 55]} fill="brand01" />
                    </Box>
                    <Flex
                      width="100%"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      display={{ default: "flex", s: "none" }}
                      bg="#f0f6f4"
                    >
                      <Text textStyle="h6" color="brand01">
                        &#10095;
                      </Text>
                    </Flex>
                  </NextBtn>

                  <Flex
                    justifyContent="space-between"
                    position="absolute"
                    bottom="-60px"
                    height="50px"
                    width={["200px", "auto"]}
                  >
                    <Text color="support01" textStyle="p2_t" ml="spc1">
                      <ErrorMessage name="email" className="errorMsg" component="p" />
                    </Text>
                  </Flex>
                </StyledBox>
                <ProgressBar
                  width="100%"
                  bg={
                    (touched.email && errors.email) || (touched.check && errors.check)
                      ? "support01"
                      : touched.email && !errors.email && !errors.check
                      ? "brand01"
                      : null
                  }
                />
                <Box
                  width="100%"
                  textAlign="left"
                  mt={["45px"]}
                  ml={["5px"]}
                  position="absolute"
                  top="100%"
                >
                  <Flex alignItems="center" as="label" htmlFor="newsletter-checkbox">
                    <Box
                      mr="10px"
                      as="input"
                      type="checkbox"
                      name="check"
                      checked={values.check}
                      id="newsletter-checkbox"
                      onChange={() => setFieldValue("check", !values.check)}
                    />
                    <Text color="text03" textStyle="p1_t" color="textLight">
                      Acepto la
                    </Text>
                    <Text color="brand01" textStyle="p1_t" ml="5px">
                      <Linkexternal href="/privacy" title="Política de Privacidad">
                        Política de Privacidad
                      </Linkexternal>
                    </Text>
                  </Flex>
                  <Box ml="spc1" mt="5px">
                    <Text color="support01" textStyle="p2_t">
                      <ErrorMessage name="check" className="errorMsg" component="p" />
                    </Text>
                  </Box>
                </Box>
              </Box>

              <AnimatePresence>
                {serverState && (
                  <motion.div
                    initial={{ y: 70, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 70, opacity: 0 }}
                    transition={{ duration: 0.7 }}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Box
                      width="100%"
                      height="100%"
                      position="relative"
                      mb={["40px", "40px", "56px"]}
                    >
                      <Text
                        display="flex"
                        textStyle="subtitle"
                        border={border}
                        bg={bg ? bg : null}
                        borderRadius="2px"
                        width="100%"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        mb="20px"
                      >
                        Gracias, pronto te contactaremos
                      </Text>
                      <Box position={buttonPosition} right="0">
                        <Btn
                          type="reset"
                          size="regular"
                          width={buttonWidth}
                          height={["40px", "40px", "56px"]}
                          onClick={() => setClose()}
                        >
                          {buttonText}
                        </Btn>
                      </Box>
                    </Box>
                  </motion.div>
                )}
              </AnimatePresence>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default SubscribeForm
