import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import { Box } from "@sqymagma/elements"

import Flash from "../modules/m33_FlashMessage"
import LastMinute from "../modules/m34_LastMinute"

const Marketing = () => {
  const [modal, setModal] = useState(true)
  const [cookies, setCookie] = useCookies(["showbanner"])
  const [faldon, setFaldon] = React.useState(true)

  //console.log(cookies.showbanner)
  // console.log("cookie " + cookies.showbanner + " | modal " + modal + " | faldon " + faldon)

  const toggleModal = () => {
    setModal(!modal)
  }
  const quitFaldon = () => {
    setFaldon(false)
    setModal(false)
    setCookie("showbanner", false)
  }
  useEffect(() => {
    if (typeof cookies.showbanner === "undefined") {
      setCookie("showbanner", true)
    } else if (cookies.showbanner === "false" || cookies.showbanner === false) {
      // console.log("hellooooooo")
      setModal(false)
      setFaldon(false)
    }
  })

  return (
    <>
      <Box position="absolute" top="0px" id="Hello" zIndex={100} width="100%">
        <LastMinute
          lastmintext="Ahora puedes recibir quincenalmente la mejor propuesta de inversión de local."
          lastminlink="Me interesa"
          openModal={() => setModal(true)}
          close={() => quitFaldon()}
          show={cookies.showbanner ? faldon : false}
        />
      </Box>
      <Flash
        title="¿Buscas rentabilidades entre el 8-11% de tu inversión vía alquiler de viviendas?"
        text="Suscríbete a nuestra lista de correo y conoce quincenalmente la mejor propuesta de inversión que tengamos en ese momento para adquirir un local comercial y cambiar su uso a vivienda."
        show={cookies.showbanner ? modal : false}
        toggle={() => toggleModal()}
      />
    </>
  )
}

export default Marketing
