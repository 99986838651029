import React from "react"
import { Button } from "@sqymagma/elements"
import styled from "styled-components"

const LSBtn = styled(Button)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${p => (p.width ? p.width : "auto")};
  height: ${p => (p.height ? p.height : "auto")};
  background-color: ${p => (p.type === "secondary" ? p.theme.color.text02 : p.theme.color.brand01)};
  font-family: ${p => p.theme.fontFamily.secondary};
  color: ${p => (p.type === "secondary" ? p.theme.color.brand01 : p.theme.color.text02)};
  line-height: ${p => (p.size === "small" ? "32px" : p.size === "regular" ? "40px" : "56px")};
  padding: ${p =>
    p.padding
      ? p.padding
      : p.size === "small"
      ? "0px 12px"
      : p.size === "regular"
      ? "0px 16px"
      : "0px 32px"};
  font-weight: 500;
  border: ${p => (p.type === "secondary" ? "1px solid" : "none")};
  border-color: ${p => (p.type === "secondary" ? p.theme.color.brand01 : "none")};
  &:hover {
    background-color: ${p =>
      p.type === "secondary" ? p.theme.color.hoverSecondary : p.theme.color.hoverPrimary};
  }
  &:focus {
    background-color: ${p =>
      p.type === "secondary" ? p.theme.color.focusSecondary : p.theme.color.focusPrimary};
  }
  display: ${p => (p.display ? p.display : "flex")};
  justify-content: center;
  align-items: center;
  margin: ${p => (p.margin ? p.margin : "auto")};
`

const Btn = ({
  as,
  children,
  href,
  size,
  type,
  width,
  height,
  onClick,
  padding,
  margin,
  display,
  ...props
}) => {
  return (
    <>
      <LSBtn
        as={as}
        type={type}
        href={href}
        size={size}
        width={width}
        height={height}
        onClick={onClick}
        padding={padding}
        margin={margin}
        display={display}
        title={children}
        {...props}
      >
        {children}
      </LSBtn>
    </>
  )
}

export default Btn
