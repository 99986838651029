import React from "react"
import { Flex, Box } from "@sqymagma/elements"

const Tick = ({ align, bg, ...props }) => {
  return (
    <Flex
      justifyContent={align !== null ? align : "left"}
      py={["spc", "spc", "spc2", "spc2", "spc3"]}
      w="100%"
      {...props}
    >
      <Box bg={bg} width="32px" height="2px" />
    </Flex>
  )
}

Tick.defaultProps = {
  bg: "brand01",
}

export default Tick
