import React from "react"
import { Flex, Text } from "@sqymagma/elements"
import styled from "styled-components"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import Modal from "../components/modal.js"
import Tick from "../components/tick"
import SubscribeForm from "./SubscribeForm"

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
`

const M33FlashMessage = ({ title, text, contentVariant, show, toggle, ...props }) => {
  return (
    <Flex {...props}>
      <Modal isActive={show} toggleFunction={toggle} className="Lamodal">
        <Flex
          width={["calc(100% - 32px)", "640px", "640px", "768px", "896px"]}
          bg="brand02"
          p={["32px", "72px", null, null, "120px"]}
          position="relative"
          className="Modalcontent"
          overflow="auto"
          flexDirection="column"
          variants={contentVariant}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <CloseButton
            onClick={e => {
              e.stopPropagation()
              toggle()
            }}
          >
            <IcnSystem
              name="close"
              height="24px"
              fill="brand01"
              css={`
                &:hover {
                  * {
                    opacity: 0.9;
                  }
                }
              `}
            />
          </CloseButton>
          <Flex flexDirection="column">
            <Flex flexDirection="row" alignItems="center" mb="30px">
              <IcnSystem
                name="lsicon"
                height={["22px", "28px", "28px", "33px"]}
                fill="brand03"
                margin={["0px 4px", "-1px 8px 1px 0"]}
              />
              <IcnSystem
                name="lsname"
                height={["17px", "23px", "23px", "27px"]}
                fill="text01"
                margin={["7px 8px 0px 8px", "8px 8px 0px"]}
              />
            </Flex>
            <Text textStyle="h3" color="text01">
              {title}
            </Text>
            <Tick />
            <Text textStyle="p" color="text03" mb="0px">
              {text}
            </Text>
            <SubscribeForm
              width="auto"
              buttonWidth="100%"
              buttonText="Entrar al site"
              buttonPosition="static"
              border="1px solid #e2cdbf"
              bg="#e2cdbf47"
              tagidsubemail="TM84-Velo-ModuloCaptacionLeads-BotonNext"
              margin={["spc2"]}
              setClose={() => {
                toggle()
              }}
            />
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default M33FlashMessage
