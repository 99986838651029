import React from "react"
import { Flex } from "@sqymagma/elements"
import styled from "styled-components"

const LoaderContainer = styled(Flex)`
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  opacity: 0.6;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.05);
`

const Spinner = styled.div`
  &,
  &:before,
  &:after {
    background: #999;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
  }
  &:before {
    left: -1.5em;
  }
  & {
    text-indent: -9999em;
    margin: 8em auto;
    position: relative;
    font-size: 11px;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  &:after {
    left: 1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0 #999;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em #999;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0 #999;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em #999;
      height: 5em;
    }
  }
`

const Loader = ({ display }) => {
  return (
    <LoaderContainer
      zIndex={1000}
      bg="bg01"
      justifyContent="center"
      alignItems="center"
      display={display}
    >
      <Spinner />
    </LoaderContainer>
  )
}

export default Loader
