// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casos-juan-de-iziar-js": () => import("./../src/pages/casos/juan-de-iziar.js" /* webpackChunkName: "component---src-pages-casos-juan-de-iziar-js" */),
  "component---src-pages-casos-puerto-real-js": () => import("./../src/pages/casos/puerto-real.js" /* webpackChunkName: "component---src-pages-casos-puerto-real-js" */),
  "component---src-pages-casos-san-venancio-js": () => import("./../src/pages/casos/san-venancio.js" /* webpackChunkName: "component---src-pages-casos-san-venancio-js" */),
  "component---src-pages-casos-zuera-js": () => import("./../src/pages/casos/zuera.js" /* webpackChunkName: "component---src-pages-casos-zuera-js" */),
  "component---src-pages-como-lo-hacemos-js": () => import("./../src/pages/como-lo-hacemos.js" /* webpackChunkName: "component---src-pages-como-lo-hacemos-js" */),
  "component---src-pages-convertir-local-en-vivienda-madrid-casos-practicos-js": () => import("./../src/pages/convertir-local-en-vivienda-madrid-casos-practicos.js" /* webpackChunkName: "component---src-pages-convertir-local-en-vivienda-madrid-casos-practicos-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invertir-en-locales-comerciales-js": () => import("./../src/pages/invertir-en-locales-comerciales.js" /* webpackChunkName: "component---src-pages-invertir-en-locales-comerciales-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-living-alternatives-quienes-somos-js": () => import("./../src/pages/living-alternatives-quienes-somos.js" /* webpackChunkName: "component---src-pages-living-alternatives-quienes-somos-js" */),
  "component---src-pages-preguntas-frecuentes-como-hacer-un-cambio-de-uso-js": () => import("./../src/pages/preguntas-frecuentes-como-hacer-un-cambio-de-uso.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-como-hacer-un-cambio-de-uso-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

